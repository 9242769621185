<template>
  <div class="page">
    <router-view/>
  </div>
</template>

<script>

export default {
  components: {
  },
  setup() {
    return {};
  }
};
</script>

<style lang="scss">
@import "../../css/app.scss";
</style>