import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/bitcoin-on-the-beach-2022',
        name: 'bitcoin-on-the-beach-2022',
        component: () => import(/* webpackChunkName: "about" */ '../views/BitcoinOnTheBeach.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
