import { createApp, reactive } from 'vue';
import App from '@/js/components/App';
import router from './router'

fetch('/icons.svg').then(response => response.text()).then(data => {
    document.body.insertAdjacentHTML('afterbegin', data);
});

export const isDev = process.env.NODE_ENV === "development";
export const stacksContractAddress = isDev ? 'SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.bitcoin-on-the-beach' : 'SP6P4EJF0VG8V0RB3TQQKJBHDQKEF6NVRD1KZE3C.bitcoin-on-the-beach';

export const getDefaultStacksAddress = () => {
    return isDev ? 'ST1JCPNPAMAQJ364AFHPTW3HY7X0HYZ3TJ0FBHDSE' : 'SP1JCPNPAMAQJ364AFHPTW3HY7X0HYZ3TJ0ZDGWZH';
};

export const state = reactive({
    price: 0,
    userData: null,
    stacksUser: getDefaultStacksAddress(),
    stacksLoggedIn: false,
    icon: 'https://satoshibles.com/images/fav.png',
});

createApp(App).use(router).mount('#app');
