<template>
  <div class="content">
    <h1>Satoshibles Events</h1>
    <p>See below for a list of upcoming events:</p>
    <ul>
      <li><router-link to="/bitcoin-on-the-beach-2022">23rd March - Bitcoin on the Beach 2022</router-link></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {}
};
</script>
